import React, { useState, useEffect } from 'react';
import { UserRoundCog, User, EarthLock } from "lucide-react";
import Sidebar, { SidebarItem } from "../Components/SettingsSidebar";
import TheNav from "../Components/navbar";
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { auth, db } from "../Context/firebaseConfig";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import SecurityQuestions from '../Components/securityQuestions';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import sanitizeFilename from "sanitize-filename";

const useUserData = (userId) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!userId) return;

        const fetchData = async () => {
            const docRef = doc(db, "Users", userId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUserData(docSnap.data());
            } else {
                console.log("No such document!");
            }
            setLoading(false); // Set loading to false once data is fetched
        };

        fetchData();
    }, [userId]);

    return { userData, loading };
};


export default function CreatorSettings() {
    const [activeTab, setActiveTab] = useState('Edit Profile');
    const navigate = useNavigate();
    const { userData: user, loading } = useUserData(auth.currentUser?.uid);

    useEffect(() => {
        if (!loading && user) {
            if (user.profileInfo.role === 'editor' || user.profileInfo.role === 'Editor') {
                navigate('/editorSettings');
            }
        }
    }, [user, loading, navigate]);

    return (
        <>
            <TheNav />
            <div className="flex">
                <Sidebar>
                    <SidebarItem
                        icon={<UserRoundCog size={20} />}
                        text="Edit Profile"
                        alert
                        active={activeTab === 'Edit Profile'}
                        onClick={() => setActiveTab('Edit Profile')}
                    />
                    <SidebarItem
                        icon={<User size={20} />}
                        text="Account"
                        active={activeTab === 'Account'}
                        onClick={() => setActiveTab('Account')}
                    />
                    <SidebarItem
                        icon={<EarthLock size={20} />}
                        text="Portfolio"
                        active={activeTab === 'Portfolio'}
                        onClick={() => setActiveTab('Portfolio')}
                    />
                </Sidebar>
                <main
                    className="w-full p-4 flex-1 max-w-7xl mx-auto"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                    }}
                >
                    {activeTab === 'Edit Profile' && (
                        <div className="w-full">
                            <EditProfile user={user} />
                        </div>
                    )}
                    {activeTab === 'Account' && (
                        <div className="w-full">
                            <Account />
                        </div>
                    )}
                    {activeTab === 'Portfolio' && (
                        <div className="w-full">
                            <Portfolio />
                        </div>
                    )}
                </main>
            </div>
        </>

    );
}

function EditProfile() {
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [file, setFile] = useState(null);
    const [about, setAbout] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [tiktok, setTikTok] = useState("");
    const [twitch, setTwitch] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [twitter, setTwitter] = useState("");

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                try {
                    const userDoc = doc(db, "Users", user.uid);
                    const docSnap = await getDoc(userDoc);
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        console.log("Fetched Data:", data);

                        setUsername(data.profileInfo?.username || "");
                        setPhoneNumber(data.profileInfo?.phoneNumber || "");
                        setAbout(data.profileInfo?.about || "");
                        setImgUrl(data.profileInfo?.profilePicUrl || "");
                        setTikTok(data.socials?.tiktok || "");
                        setTwitch(data.socials?.twitch || "");
                        setInstagram(data.socials?.instagram || "");
                        setYoutube(data.socials?.youtube || "");
                        setTwitter(data.socials?.twitter || "");
                    } else {
                        console.error("No such document!");
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            } else {
                console.error("No authenticated user found.");
            }
        };

        fetchUserData();
    }, []);
    const [imageSizeError, setImageSizeError] = useState(false);
    const uploadFile = async (file) => {
        try {
            const storage = getStorage();
            const storageRef = ref(storage, `profilePics/${auth.currentUser.uid}`);
            const uploadResult = await uploadBytes(storageRef, file);

            // Get the download URL from Firebase Storage
            const downloadURL = await getDownloadURL(uploadResult.ref);
            console.log("Download URL:", downloadURL);

            // Update Firestore with the new profile picture URL
            const userRef = doc(db, "Users", auth.currentUser.uid);
            await updateDoc(userRef, {
                "profileInfo.profilePicUrl": downloadURL,
            });

            console.log("Firestore updated successfully");
            setImgUrl(downloadURL); // Update state for UI
        } catch (error) {
            console.error("Error uploading file or updating Firestore:", error);
        }
    };


    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size > 1000000) {
                setImageSizeError(true);
                return;
            }
            setImageSizeError(false); // Clear any previous error
            const sanitizedFileName = sanitizeFilename(file.name);
            const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });
            await uploadFile(sanitizedFile);
        }
    };


    const handleUpload = async () => {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePics/${auth.currentUser.uid}`);
        const userRef = doc(db, "Users", auth.currentUser.uid);

        try {
            const uploadResult = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(uploadResult.ref);
            await updateDoc(userRef, {
                "profileInfo.profilePicUrl": downloadURL,
            });
            setImgUrl(downloadURL);
        } catch (error) {
            console.error("Error uploading file or updating profile:", error);
            alert("Error uploading file or updating profile: " + error.message);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const userRef = doc(db, "Users", auth.currentUser.uid);
            const docSnap = await getDoc(userRef);

            if (docSnap.exists()) {
                //const currentData = docSnap.data();
                const updates = {};

                if (phoneNumber) updates["profileInfo.phoneNumber"] = phoneNumber;
                if (about) updates["profileInfo.about"] = about;
                if (imgUrl) updates["profileInfo.profilePicUrl"] = imgUrl;

                if (tiktok) updates["socials.tiktok"] = tiktok;
                if (twitch) updates["socials.twitch"] = twitch;
                if (instagram) updates["socials.instagram"] = instagram;
                if (youtube) updates["socials.youtube"] = youtube;
                if (twitter) updates["socials.twitter"] = twitter;

                await updateDoc(userRef, updates);

                alert("Profile Updated Successfully!");
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("Error updating profile: " + error.message);
        }
    };



    return (
        <>
            <form onSubmit={handleSave}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Username
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    placeholder={username}
                                                    autoComplete="username"
                                                    className="block w-full flex-1 min-w-0 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border rounded-none rounded-r-md"
                                                    value={username}
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    {/* <div className="flex-1">
                                        <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                            Phone Number
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                placeholder={phoneNumber || "Phone Number"}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber()}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                    About
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        id="about"
                                        name="about"
                                        rows={3}
                                        placeholder={about || "Write a few sentences about yourself."}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={about}
                                        onChange={(e) => setAbout(DOMPurify.sanitize(e.target.value))}
                                    />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="twitch" className="block text-sm font-medium leading-6 text-gray-900">
                                    Twitch
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="twitch"
                                        type="text"
                                        name="twitch"
                                        placeholder={twitch || "Twitch URL"}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={twitch}
                                        onChange={(e) => setTwitch(DOMPurify.sanitize(e.target.value))}
                                    />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Your Twitch URL.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="instagram" className="block text-sm font-medium leading-6 text-gray-900">
                                    Instagram
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        id="instagram"
                                        name="instagram"
                                        placeholder={instagram || "Instagram URL"}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={instagram}
                                        onChange={(e) => setInstagram(DOMPurify.sanitize(e.target.value))}
                                    />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Your Instagram URL.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="tiktok" className="block text-sm font-medium leading-6 text-gray-900">
                                    TikTok
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        id="tiktok"
                                        name="tiktok"
                                        placeholder={tiktok || "TikTok URL"}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={tiktok}
                                        onChange={(e) => setTikTok(DOMPurify.sanitize(e.target.value))}
                                    />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Your TikTok URL.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="youtube" className="block text-sm font-medium leading-6 text-gray-900">
                                    YouTube
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="youtube"
                                        name="youtube"
                                        type="text"
                                        placeholder={youtube || "YouTube URL"}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={youtube}
                                        onChange={(e) => setYoutube(DOMPurify.sanitize(e.target.value))}
                                    />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Your YouTube URL.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="twitter" className="block text-sm font-medium leading-6 text-gray-900">
                                    Twitter
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="twitter"
                                        name="twitter"
                                        type="text"
                                        placeholder={twitter || "Twitter URL"}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={twitter}
                                        onChange={(e) => setTwitter(DOMPurify.sanitize(e.target.value))}
                                    />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Your Twitter URL.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                    Photo
                                </label>
                                <div className="mt-2 flex items-center gap-x-3">
                                    <div className="w-24 h-24 flex items-center justify-center overflow-hidden rounded-full border border-gray-300">
                                        <img
                                            src={imgUrl}
                                            alt="Profile Image"
                                            className="rounded-full w-full h-full object-cover aspect-square"
                                        />
                                    </div>

                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        accept="image/png, image/jpeg, image/gif"
                                        className="block w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 cursor-pointer focus:outline-none focus:ring-transparent"
                                    />
                                    <button
                                        onClick={handleUpload}
                                        className="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400"
                                    >
                                        Upload Photo
                                    </button>
                                </div>
                                {imageSizeError && (
                                    <p className="text-red-500 text-xs">
                                        The file size exceeds 1MB. Please upload a smaller image.
                                    </p>
                                )}
                            </div>


                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="button"
                            className="bg-black text-white px-4 py-2 rounded-md"
                            style={{ backgroundColor: "black", color: "white" }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-indigo-500 text-white px-4 py-2 rounded-md"
                            style={{ backgroundColor: "#6366F1", color: "white" }} // Ensuring indigo-500 (#6366F1) is applied
                        >
                            Save
                        </button>
                    </div>




                </div>
            </form>

        </>
    )
}

function Account() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            const userRef = doc(db, "Users", auth.currentUser.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                const userData = docSnap.data();
                setFirstName(userData.profileInfo?.firstName || '');
                setLastName(userData.profileInfo?.lastName || '');
                setEmail(userData.profileInfo?.email || '');
            } else {
                console.log("No user data available");
            }
        };

        fetchUserData();
    }, []);

    const handleSave = async (e) => {
        e.preventDefault();
        const userRef = doc(db, "Users", auth.currentUser.uid);
        try {
            await updateDoc(userRef, {
                "profileInfo.firstName": firstName,
                "profileInfo.lastName": lastName,
            });
            alert("Account updated successfully!");
        } catch (error) {
            console.error("Error updating account:", error);
            alert("Error updating account: " + error.message);
        }
    };

    return (
        <>
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Account Settings</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

                <form onSubmit={handleSave} className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            First Name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={firstName}
                                onChange={(e) => setFirstName(DOMPurify.sanitize(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Last Name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={lastName}
                                onChange={(e) => setLastName(DOMPurify.sanitize(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email Address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                placeholder={email || "Email"}
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={email}
                            />
                        </div>
                    </div>

                    <div className="col-span-full flex justify-end gap-x-6">
                        <button
                            type="submit"
                            className="rounded-md bg-[#4F46E5] px-3 py-2 text-sm font-semibold text-white shadow-sm"
                            style={{ backgroundColor: '#4F46E5', color: '#FFFFFF' }} // Tailwind indigo-600 hex code
                        >
                            Save Changes
                        </button>
                    </div>

                </form>
            </div>
        </>
    );
}


// function Security() {
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [securityQuestion, setSecurityQuestion] = useState('');
//     const [securityAnswer, setSecurityAnswer] = useState('');

//     useEffect(() => {
//         const fetchSecurityDetails = async () => {
//             const userRef = doc(db, 'Users', auth.currentUser.uid);
//             // console.log("Fetching security details for user:", auth.currentUser.uid);
//             const docSnap = await getDoc(userRef);
//             if (docSnap.exists() && docSnap.data()?.security) {
//                 console.log("Security details found:", docSnap.data().security);
//                 setSecurityQuestion(docSnap.data().security?.question || '');
//                 setSecurityAnswer(docSnap.data().security?.answer || '');
//             } else {
//                 // console.log("No security details found or document does not exist.");
//             }
//         };

//         fetchSecurityDetails();
//     }, []);

//     const handleSecurityQuestionEdit = () => {
//         setIsModalOpen(true);
//     };

//     const handleSaveSecurityQuestion = async (question, answer) => {
//         const userRef = doc(db, 'Users', auth.currentUser.uid);
//         console.log("Saving security question for user:", auth.currentUser.uid);
//         try {
//             await updateDoc(userRef, {
//                 'security.questions?.question': question,
//                 'security.questions?.answer': answer
//             });
//             console.log("Security question updated:", question);
//             setSecurityQuestion(question);
//             setSecurityAnswer(answer);
//             alert("Security question updated successfully!");
//             setIsModalOpen(false);
//         } catch (error) {
//             console.error("Error updating security question:", error);
//             alert("Error updating security question: " + error.message);
//         }
//     };

//     return (
//         <>
//             <h2 className="text-base font-semibold leading-7 text-gray-900">Security</h2>
//             <p className="mt-1 text-sm leading-6 text-gray-600">Extra steps to secure your account.</p>
//             <div className="flex justify-between items-center py-4">
//                 <div className="font-bold text-gray-500">PHONE VERIFICATION</div>
//                 <div className="text-gray-700">Your phone is not verified. Click 'Edit' to change your phone number.</div>
//                 <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded">
//                     Edit
//                 </button>
//             </div>
//             <div className="flex justify-between items-center py-4">
//                 <div className="font-bold text-gray-500">SECURITY QUESTIONS</div>
//                 <div className="text-gray-700">
//                     {securityQuestion && securityAnswer ? "Would you like to change your security question?" : "By creating a security question, you will add an additional layer of protection for your account."}
//                 </div>
//                 <button onClick={handleSecurityQuestionEdit} className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded">
//                     Edit
//                 </button>
//             </div>
//             {isModalOpen && <SecurityQuestions isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSave={handleSaveSecurityQuestion} />}
//         </>
//     );
// }

function Portfolio() {
    const [tagline, setTagline] = useState('');
    const [questionOne, setQuestionOne] = useState('');
    const [questionTwo, setQuestionTwo] = useState('');
    const [questionThree, setQuestionThree] = useState('');
    const [questionFour, setQuestionFour] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            const userRef = doc(db, "Users", auth.currentUser.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                const userData = docSnap.data();
                setTagline(userData.profileInfo?.tagline || '');
                setQuestionOne(userData.profileInfo.questions?.questionOne || '');
                setQuestionTwo(userData.profileInfo.questions?.questionTwo || '');
                setQuestionThree(userData.profileInfo.questions?.questionThree || '');
                setQuestionFour(userData.profileInfo.questions?.questionFour || '');
            } else {
                console.log("No user data available");
            }
        };

        fetchUserData();
    }, []);

    const handleSave = async (e) => {
        e.preventDefault();
        const userRef = doc(db, "Users", auth.currentUser.uid);
        try {
            await updateDoc(userRef, {
                "profileInfo.tagline": tagline,
                "profileInfo.questions.questionOne": questionOne,
                "profileInfo.questions.questionTwo": questionTwo,
                "profileInfo.questions.questionThree": questionThree,
                "profileInfo.questions.questionFour": questionFour,
            });
            alert("Account updated successfully!");
        } catch (error) {
            console.error("Error updating account:", error);
            alert("Error updating account: " + error.message);
        }
    };

    return (
        <>
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Account Settings</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

                <form onSubmit={handleSave} className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                    <div className="sm:col-span-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            What is your tagline?
                        </label>
                        <div className="mt-2">
                            <input
                                id="tagline"
                                type="text"
                                placeholder={tagline || "Tagline"}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={tagline}
                                onChange={(e) => setTagline(DOMPurify.sanitize(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            What things would make the editing gig the easiest for you?
                        </label>
                        <div className="mt-2">
                            <input
                                id="questionOne"
                                type="text"
                                placeholder={questionOne || "Question One"}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={questionOne}
                                onChange={(e) => setQuestionOne(DOMPurify.sanitize(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            How can you make the editing gig easiest for the other person?
                        </label>
                        <div className="mt-2">
                            <input
                                id="questionTwo"
                                type="text"
                                placeholder={questionTwo || "Question Two"}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={questionTwo}
                                onChange={(e) => setQuestionTwo(DOMPurify.sanitize(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            What are the pain points others may have while working with you?
                        </label>
                        <div className="mt-2">
                            <input
                                id="questionOne"
                                type="text"
                                placeholder={questionThree || "Question Three"}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={questionThree}
                                onChange={(e) => setQuestionThree(DOMPurify.sanitize(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            What do you bring to the table?
                        </label>
                        <div className="mt-2">
                            <input
                                id="questionOne"
                                type="text"
                                placeholder={questionFour || "Question Four"}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={questionFour}
                                onChange={(e) => setQuestionFour(DOMPurify.sanitize(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="col-span-full flex justify-end gap-x-6">
                        <button
                            type="submit"
                            style={{ marginTop: "20px", backgroundColor: "rgb(139, 92, 246)" }}
                            className="rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
                        >
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}